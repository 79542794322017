const readOurDocumentdata = {
    english: {
        title: "READ OUR DOCUMENTS",
        description: "Here is our full documents that help you to understand deeply about us and our operation"
    },
    arabic: {
        title: "اقرأ وثائقنا",
        description: "إليك مستنداتنا الكاملة التي تساعدك على الفهم العميق عنا وعن عملياتنا"
    }
}

export default readOurDocumentdata;